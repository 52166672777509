import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "./auth.service";
import { filter } from "rxjs/operators";
import { BehaviorSubject } from "rxjs";

export enum Render360Preset {
  Low = "Low",
  Middle = "Middle",
  High = "High",
  Ultra = "Ultra",
}

export enum TaskStatus {
  New,
  Performed,
  Suspended,
  Completed,
  Canceled,
  Error,
  Completion,
}

export interface TaskInfo {
  id: number;
  guid: string;
  fileName: string;
  message: string;
  dateCreate: string;
  resultStatus: TaskStatus;
  renderTime: string;
  preview: string;
}

@Injectable({
  providedIn: "root",
})
export class RenderService {
  isOnlineAuth = new BehaviorSubject<boolean | undefined>(undefined);
  token: string = null;

  constructor(private http: HttpClient, private auth: AuthService) {
    this.isOnlineAuth.next(false);
    this.auth.isAuthenticated.pipe(filter((v) => !!v)).subscribe(async (_) => {
      await this.loginToOnLine();
    });
  }

  private async loginToOnLine() {
    let response = await this.http
      .get<any>(`/api/render/login/${this.auth.userId}`)
      .toPromise();

    this.token = response.token;
    this.isOnlineAuth.next(true);
  }

  getUserTasks(pageNumber: number) {
    return this.http
      .post<any>(`/api/render/gettasks/${pageNumber}`, {
        token: this.token,
      })
      .toPromise();
  }

  downloadResult(taskId: number) {
    return this.http
      .post(
        `/api/render/${taskId}/downloadresult`,
        {
          token: this.token,
        },
        { responseType: "blob" }
      )
      .toPromise();
  }

  downloadSharingResult(guid: string) {
    return this.http
      .post(
        `/api/render/${guid}/downloadresult`,
        {
          asUrl: true,
        },
        { responseType: "text" }
      )
      .toPromise();
  }

  createShareLink(guid: string) {
    return window.location.origin + `/render/view360/${guid}`;
  }

  createTask(fileId: string, task360: boolean = false) {
    const renderSettings = {
      render360: task360,
    };

    // TODO: get camera and lights info
    const camera = null;
    const lights = null;

    return this.http
      .post<number>(`/api/render/${fileId}/createtask`, {
        token: this.token,
        renderSettings,
        camera,
        lights,
      })
      .toPromise();
  }
}
